import React, { useState, useContext } from "react";
import "./navbar.css";
import logo from "../../Assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { WalletConnectContext } from "../../hooks/WalletLogin";
const Navbar = () => {
  const navigate = useNavigate();
  const { isWalletLogin, logout } = useContext(WalletConnectContext);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const myHome = () => {
    logout();
  };
  const closeHam = () => {
    setModal(false);
    setTimeout(() => {
      setOpen(false);
      document.getElementsByTagName('body')[0].style.overflow = 'visible'
    }, 700);
  };
  const openHam = () => {
    setModal(true);
    setOpen(true);
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'
  };
  // console.log(isWalletLogin)
  return (
    <div className="navbar">
      <div className="balancingdiv" />
      <a target="_blank" href='https://8sian.io'>
        <img src={logo} className="navbar-logo" alt="8sian"  />
        </a>
      <div
        className="navbar-connect"
        onClick={
          isWalletLogin ? () => myHome() : () => navigate("/connect-wallet")
        }
      >
        {isWalletLogin ? "Logout" : "Connect"}
      </div>
      {open ? (
        <MdOutlineClose
          className="navbar-menu2"
          alt="Menu"
          onClick={() => closeHam()}
        />
      ) : (
        <GiHamburgerMenu
          className="navbar-menu"
          alt="Menu"
          onClick={() => openHam()}
        />
      )}
      {open ? (
        <div className={modal ? "ham-dropdown" : "ham-dropdown-close"}>
          <div className={modal?"nav-links-mob":"nav-links-mob-close"}>
            <Link
              className="nav-link-mob"
              to="/"
              onClick={() => setOpen(false)}
            >
              Home
            </Link>
            <Link
              className="nav-link-mob"
              to={isWalletLogin ? "/" : "/connect-wallet"}
              onClick={() => setOpen(false)}
            >
              My 8SIANs
            </Link>
          </div>
          <div className="btn-cont-mob">
            <div
              className="nav-btn-mob"
              onClick={
                isWalletLogin
                  ? () => myHome()
                  : () => navigate("/connect-wallet")
              }
            >
              {isWalletLogin ? "Logout" : "Connect"}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Navbar;
