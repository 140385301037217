const imgArr = [
  {
    index: 2,
    image: './images/Earring Lantern.png'
  },
  {
    index: 3,
    image: './images/Hair Bunnyhat.png'
  },
  {
    index: 4,
    image: './images/Earring Bunnygold.png'
  },
  {
    index: 5,
    image: './images/Hair Mandarin.png'
  },
  {
    index: 6,
    image: './images/Earring Bunny.png',
  },
  {
    index: 7,
    image: './images/BGFlowers.png',
  },
  {
    index: 8,
    image: './images/BGRabbit.png',
  },
  {
    index: 9,
    image: './images/Hair Chinesebuns.png',
  },
];
export default imgArr;
