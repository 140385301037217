import React from "react";
import "./mynftcard.css";
import { Link } from "react-router-dom";
import back from "../../Assets/back.png";

const MyNftCard = ({ data }) => {
  // console.log(data)
  return (
    <Link
      to="/nft"
      state={{
        name: data.name,
        description: data.description,
        traits: data.traits,
      }}
      className="my-nft-card"
    >
      <img src={data.image_url} className="my-nft-card-img" alt="nft" />
      <div className="my-nft-card-btm">
        <div className="my-nft-card-btm-txt">{data.name}</div>
        <img src={back} alt="Back" className="my-nft-card-btm-img" />
      </div>
    </Link>
  );
};

export default MyNftCard;
