import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import Loader from "../../Components/Loader/Loader"
import "../Home/home.css";

const AdminModal = () => {
    const [ pfps, setpfps ] = useState([])
    const [ loaded, setloaded ] = useState(false)

    useEffect(() => {
        axios.get('https://8sian.foxledger.io/auth/getDownloadedPFPs').then((res) => {
            setpfps(res.data.pfps)
            setloaded(true)
        })
    }, [])

    if (!loaded) {
        return <Loader />
    }

  return (
    <>
      <div className="my8sian">
        <Navbar />
        <h1 className="my8sian-header">PFP Downloads</h1>
        <div style={{ minHeight: '60vh' }} className="my8sian-con">
            {pfps.length > 0 && pfps.map((pfp) => {
                return (<div style={{ display: 'flex', gap: '40px', alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{ width: '100px' }} src={pfp.imageUrl} />
                    <h1 style={{ fontFamily: "Montserrat", color: 'white', fontSize: '24px', fontWeight: 500 }}>{pfp.walletAddress}</h1>
                    <h1 style={{ fontFamily: "Montserrat", color: 'white', fontSize: '24px', fontWeight: 500 }}>{pfp.tokenId}</h1>
                    <span style={{ fontFamily: "Montserrat", color: 'white', fontSize: '24px', fontWeight: 500 }}>{ pfp.attributes.length > 0 && pfp.attributes.map((attribute) => {
                        return <span style={{ fontFamily: "Montserrat", color: 'white', fontSize: '24px', fontWeight: 500, marginRight: '10px' }}> {attribute} </span>
                    })}</span>
                </div>)
            })}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AdminModal;
