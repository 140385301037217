import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { WalletConnectProviderHook } from "./hooks/WalletLogin";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WalletConnectProviderHook>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </WalletConnectProviderHook>
);
