import React from "react";
import "./walletcard.css";
const WalletCard2 = ({ img, title, desc, func }) => {
  const ConnectWal = async () => {
    await func();
  };
  return (
    <div className="wallet-card-2" onClick={() => ConnectWal()}>
      <img src={img} alt="Wallet" className="wallet-card-img" />
      <div className="wallet-card-txt">
        <h1 className="wallet-card-txt-heading">{title}</h1>
        <p className="wallet-card-txt-para">{desc}</p>
      </div>
    </div>
  );
};

export default WalletCard2;
