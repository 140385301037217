import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import "./my8sians.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import MyNftCard from "../../Components/MynftCard/MyNftCard";
import Skeleton from "@mui/material/Skeleton";
import { WalletConnectContext } from "../../hooks/WalletLogin";
import Loader from "../../Components/Loader/Loader"
import "../Home/home.css";
import Cart from './Cart.svg'
import arrowRight from './arrowRight.svg'

const My8sians = () => {
  
  // console.log(walletAddress);
  // const currAddress = 0x88A72b209091f4A4Ffa3514E520aDBE124cA269A;
  const { fetchNfts, nftData, nftLoaded } = useContext(WalletConnectContext);

  useEffect(() => {
    fetchNfts();
  }, []);

  if (!nftLoaded) {
    return <Loader />
  }

  return (
    <>
      <div className="my8sian">
        <Navbar />
        <h1 className="my8sian-header">My 8SIANs</h1>
        {nftData.length > 0 ? <div className="my8sian-con">
          {nftData?.map((nft) => <MyNftCard data={nft} key={nft.id}/>)}
        </div> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <img style={{ width: '200px', zIndex: 1 }} src={Cart} />
            <h1 style={{ fontFamily: 'Montserrat', color: 'white', fontSize: '24px', textAlign: 'center', fontWeight: 500, marginTop: '15px' }}>You don’t have any 8SIAN NFT <br /> in your wallet</h1>
            <a href="https://opensea.io/collection/8sian-main-collection" target="_blank" style={{ fontFamily: 'Montserrat', fontSize: '24px', background: 'white', color: '#A00137', border: 'none', marginTop: '15px', padding: '14px 30px 16px', display: 'flex', alignItems: 'center', gap: '5px', borderRadius: '8px', fontWeight: '700', fontSize: '18px', marginBottom: '60px' }}>Buy from Opensea <img src={arrowRight} /></a>
          </div>}
        <Footer />
      </div>
    </>
  );
};

export default My8sians;
