import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import { WalletConnectContext } from "./hooks/WalletLogin";
import Loader from "./Components/Loader/Loader"
import ConnectWallet from "./Pages/ConnectWallet/ConnectWallet"
import CardModal from "./Pages/CardModal/CardModal";
import AdminModal from "./Pages/AdminModal/AdminModal";
import Home from "./Pages/Home/Home"
import My8sians from "./Pages/My8sians/My8sians"
const FontFaceObserver = require('fontfaceobserver')

function App() {
  const { isWalletLogin } = useContext(WalletConnectContext);
  const [montserratLoaded, setmontserratLoaded] = useState(false)
  const [Image1Load, setImage1Load] = useState(false)
  const [Image2Load, setImage2Load] = useState(false)
  const [Image3Load, setImage3Load] = useState(false)

  var montserrat = new FontFaceObserver('Montserrat')
  montserrat
    .load()
    .then(async () => {
      setmontserratLoaded(true)
    })
    .catch((error) => {
      console.log(error)
    })

    if (!isWalletLogin ? (!montserratLoaded || !Image1Load || !Image2Load || !Image3Load) : !montserratLoaded) {
    return <>
     { !isWalletLogin && (<>
      <img style={{ display: 'none' }} onLoad={() => setImage1Load(true)}
              src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1671230404/8SIAN/UFebIk3xvu8m5IqoZc9jVgSJ5NAaTuNYqj-XT6tBZpTR3ruoY5ndrCWYjgDvZhKfH1fX2CFW_4bpJYrcbSVAVEzRjHSPSynKZTXZxEA_che1jj.webp'} />
      <img style={{ display: 'none' }} onLoad={() => setImage2Load(true)}
              src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1671230034/8SIAN/kWz9gfYnZJHMCdjX6buBPilsWBUgNGReM6sjUMb35tf1GxZMScyY1rlP2hNxgJkSn8_k2xry9ynbM3hjTzTZs7urZTtbeoi1brxehg_nievkd.webp'} />
      <img style={{ display: 'none' }} onLoad={() => setImage3Load(true)}
              src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1671230036/8SIAN/POJBlDYKGsmi_0EL47drlz1eF9zY9KOjBtJB13QMOYUy4RJiEWEYfA8rMBaFg6wbm4eb5tqYG1iJzo2584VRe85BpgsHjl6ueIk-3g_hjjpmo.webp'} />
     </>)}
      <Loader />
    </>
  }
  return (
    <div className="App">
      <Router>
        <Routes>
          {isWalletLogin ? (
            <Route path="/" element={<My8sians />} />
          ) : (
            <Route path="/" element={<Home />} />
          )}
          {/* <Route path="/my-nft" element={<My8sians />} /> */}
          {isWalletLogin ? (
            <Route path="/nft" element={<CardModal />} />
          ) : (
            <Route path="/connect-wallet" element={<ConnectWallet />} />
          )}
          {isWalletLogin && (
            <Route path="/admin" element={<AdminModal />} />
          )}

          {/* 404 Error */}
          <Route
            path='/*'
            element={<Navigate to='/' />}
          />
          <Route
            path='/connect-wallet/*'
            element={<Navigate to='/' />}
          />
          <Route
            path='/nft/*'
            element={<Navigate to='/' />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
