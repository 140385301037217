import React from "react";
import "./footer.css";
import { BsTwitter } from "react-icons/bs";
import { FaDiscord } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { GiSailboat } from "react-icons/gi";
import logo from "../../Assets/logo.png";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-main">
        <div className="footer-links">
          <a
            href="https://twitter.com/8sianNFT?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
            target="_blank"
            className="footer-icon"
          >
            <BsTwitter />
          </a>
          <a
            href="https://www.instagram.com/8siannft/?hl=en"
            target="_blank"
            className="footer-icon"
          >
            <AiFillInstagram />
          </a>
          <a
            href="https://discord.com/invite/8sian"
            target="_blank"
            className="footer-icon"
          >
            <FaDiscord />
          </a>
          <a
            href="https://opensea.io/collection/8sian-main-collection"
            target="_blank"
            className="footer-icon"
          >
            <GiSailboat />
          </a>
        </div>
        <div className="footer-txt">© 8SIAN 2023 - All Rights Reserved</div>
        <a target="_blank" href='https://foxledger.studio/' className="footer-prom">
          <div className="footer-prom-upper">Developed by</div>
          <div className="footer-prom-lower">Fox Ledger Studio</div>
        </a>
      </div>
      <div className="footer-main-mob">
        <div className="footer-main-mob-top">
          <img src={logo} alt="Logo" className="footer-main-mob-top-img" />
          <div className="footer-links-mob">
            <a
              href="https://twitter.com/8sianNFT?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
              className="footer-icon-mob"
            >
              <BsTwitter />
            </a>
            <a
              href="https://www.instagram.com/8siannft/?hl=en"
              target="_blank"
              className="footer-icon-mob"
            >
              <AiFillInstagram />
            </a>
            <a
              href="https://discord.com/invite/8sian"
              target="_blank"
              className="footer-icon-mob"
            >
              <FaDiscord />
            </a>
            <a
              href="https://opensea.io/collection/8sian-main-collection"
              target="_blank"
              className="footer-icon-mob"
            >
              <GiSailboat />
            </a>
          </div>
        </div>
        <div className="footer-main-mob-btm">
          <div className="footer-txt">© 8SIAN 2023 - All Rights Reserved</div>
          <a target="_blank" href="https://foxledger.studio/" className="footer-prom">
            <div className="footer-prom-upper">Developed by</div>
            <div className="footer-prom-lower">Fox Ledger Studio</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
