import React,{useContext} from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import "./connectwallet.css";
import WalletCard from "../../Components/WalletCard/WalletCard";
import WalletCard2 from "../../Components/WalletCard/WalletCard2";
import { IoMdArrowRoundBack } from "react-icons/io";
import walCon from "../../Assets/wallCon.png";
import meta from "../../Assets/meta.png";
import torus from "../../Assets/torus.png";
import coinbase from "../../Assets/coinbase.png";
import { Link } from "react-router-dom";
import { WalletConnectContext } from "../../hooks/WalletLogin";
const ConnectWallet = () => {
  const {connectWalletThroughMetamask,connectWalletThroughCoinbase,connectWalletThrougConnectWallet} = useContext(WalletConnectContext);
  return (
    <>
    <div className="con-wall">
      <Navbar />
      <div className="con-wal">
        <div className="con-wal-box">
          <Link to="/" className="back-con-wall">
            <IoMdArrowRoundBack />
          </Link>
          <div className="con-wal-box-con">
            <h1 className="con-wal-box-con-h">Connect your wallet</h1>
            <p className="con-wal-box-con-p">
              Select the wallet provider from the list mentioned below.
            </p>
          </div>
          <div className="con-wal-box-box">
            <WalletCard2
              img={meta}
              title={"Metamask"}
              desc={
                ""
              }
              func={connectWalletThroughMetamask}
            />
            <WalletCard
              img={walCon}
              title={"WalletConnect"}
              desc={
                ""
              }
              func={connectWalletThrougConnectWallet}
              // style={{marginLeft:"10px"}}
            />
            <WalletCard
              img={coinbase}
              title={"Coinbase"}
              desc={
                ""
              }
              func={connectWalletThroughCoinbase}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div></>
  );
};

export default ConnectWallet;
