import { createContext, useState } from "react";
import Web3 from "web3";
import axios from "axios";
import WalletConnectProvider from '@walletconnect/web3-provider'
export const WalletConnectContext = createContext();

export const WalletConnectProviderHook = ({ children }) => {
  const [isWalletLogin, setWalletLogin] = useState(
    sessionStorage.getItem("walletAddress")
      ? sessionStorage.getItem("walletAddress")
      : false
  );
  const [walletAddress, setWalletAddress] = useState(
    sessionStorage.getItem("walletAddress")
      ? sessionStorage.getItem("walletAddress")
      : null
  );
  const [nftData, setNftData] = useState([]);
  const [nftLoaded, setnftLoaded] = useState(false);

  const web3 = new Web3(Web3.givenProvider);

  const fetchNfts = async () => {
    var metadata = [],
      running = true,
      next = "";
    for (let i = 0; i < 10000; i = i + 20) {
      if (next) {
        await axios
          .get(
            `https://api.opensea.io/api/v1/assets?asset_contract_address=0x198478F870d97D62D640368D111b979d7CA3c38F&owner=${walletAddress}&cursor=${next}`
          )
          .then((res) => {
            metadata = metadata.concat(res.data.assets);
            if (res.data.next) {
              next = res.data.next;
            } else {
              running = false;
            }
          })
          .catch((err) => {
            console.log(err);
            running = false;
          });
      } else {
        await axios
          .get(
            `https://api.opensea.io/api/v1/assets?asset_contract_address=0x198478F870d97D62D640368D111b979d7CA3c38F&owner=${walletAddress}`
          )
          .then((res) => {
            metadata = metadata.concat(res.data.assets);
            if (res.data.next) {
              next = res.data.next;
            } else {
              running = false;
            }
          })
          .catch((err) => {
            console.log(err);
            running = false;
          });
      }
      if (!running) {
        break;
      }
    }
    setNftData(metadata);
    // console.log(metadata)
    setnftLoaded(true);
  };

  const logout = async () => {
    try {
      sessionStorage.clear();
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const connectWalletThroughMetamask = async () => {
    try {
      document.getElementsByTagName('body')[0].style.overflow = 'visible'
      if (window.ethereum) {
        var address;
        if (window.ethereum.providers) {
          address = await window.ethereum.providers
            .find((provider) => provider.isMetaMask)
            .request({ method: "eth_requestAccounts" });
        } else {
          if (window.ethereum.isMetaMask) {
            address = await window.ethereum.request({
              method: "eth_requestAccounts",
            });
          }
        }
        if (address && address.length > 0) {
          await web3.eth
            .getAccounts()
            .then(async (data) => {
              if (data.length > 0) {
                if (data[0].length > 1) {
                  web3.eth.requestAccounts().then(async (accounts) => {
                    setWalletLogin(true);
                    setWalletAddress(accounts[0]);
                    sessionStorage.setItem("walletAddress", accounts[0]);
                  });
                }
              }
            })
            .catch((err) => {
              console.log("Wallet Error:", err);
              window.open(
                "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
                "_blank"
              );
            });
        } else {
          window.open(
            "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
            "_blank"
          );
        }
      } else {
        window.open(
          "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
          "_blank"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const connectWalletThroughCoinbase = async () => {
    try {
      document.getElementsByTagName('body')[0].style.overflow = 'visible'
      if (window.ethereum) {
        var address;
        if (window.ethereum.providers) {
          address = await window.ethereum.providers
            .find((provider) => provider.isCoinbaseWallet)
            .request({ method: "eth_requestAccounts" });
        } else {
          if (window.ethereum.isCoinbaseWallet) {
            address = await window.ethereum.request({
              method: "eth_requestAccounts",
            });
          }
        }
        if (address && address.length > 0) {
          await web3.eth
            .getAccounts()
            .then(async (data) => {
              if (data.length > 0) {
                if (data[0].length > 1) {
                  web3.eth.requestAccounts().then(async (accounts) => {
                    setWalletLogin(true);
                    setWalletAddress(accounts[0]);
                    sessionStorage.setItem("walletAddress", accounts[0]);
                  });
                }
              }
            })
            .catch((err) => {
              console.log("Wallet Error:", err);
              window.open(
                "https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad?hl=en",
                "_blank"
              );
            });
        } else {
          window.open(
            "https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad?hl=en",
            "_blank"
          );
        }
      } else {
        window.open(
          "https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad?hl=en",
          "_blank"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const connectWalletThrougConnectWallet = async () => {
    try {
      document.getElementsByTagName('body')[0].style.overflow = 'visible'
      if (window.ethereum) {
        const provider = new WalletConnectProvider({
          rpc: {
              1: 'https://cloudflare-eth.com',
              137: 'https://polygon-rpc.com',
              // 100: 'https://dai.poa.network',
          },
          bridge: 'https://bridge.walletconnect.org',
        })

      await provider.enable()
      const web3 = new Web3(provider)

      await web3.eth
        .getAccounts()
        .then(async (data) => {
          console.log(data)
          if (data.length > 0) {
            if (data[0].length > 1) {
              setWalletLogin(true);
              setWalletAddress(data[0]);
              sessionStorage.setItem("walletAddress", data[0]);
            }
          }
        })
        .catch((err) => {
          console.log("Wallet Error:", err);
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <WalletConnectContext.Provider
      value={{
        walletAddress,
        isWalletLogin,
        connectWalletThroughMetamask,
        connectWalletThroughCoinbase,
        connectWalletThrougConnectWallet,
        logout,
        nftData,
        fetchNfts,
        nftLoaded,
      }}
    >
      {children}
    </WalletConnectContext.Provider>
  );
};
