import React, { useState, useEffect, useContext } from "react";
import "./cardmodal.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import back from "../../Assets/back.png";
import fltop from "../../Assets/fltop.png";
import chno from "../../Assets/chno.png";
import hat1 from "../../Assets/addons/hat1.webp";
import hat2 from "../../Assets/addons/hat2.webp";
import hat3 from "../../Assets/addons/hat3.webp";
import hat4 from "../../Assets/addons/hat4.webp";
import earring1 from "../../Assets/addons/earring1.webp";
import earring2 from "../../Assets/addons/earring2.webp";
import frame from "../../Assets/addons/frame1.webp";
import lnyhat1 from "../../Assets/addons/lnyhat1.webp";
import lnyhat2 from "../../Assets/addons/lnyhat2.webp";
import lnyhat3 from "../../Assets/addons/lnyhat3.webp";
import lnyframe1 from "../../Assets/addons/lnyframe1.webp";
import lnyframe2 from "../../Assets/addons/lnyframe2.webp";
import lnyframe3 from "../../Assets/addons/lnyframe3.webp";
import lnyearring1 from "../../Assets/addons/lnyearring1.webp";
import lnyearring2 from "../../Assets/addons/lnyearring2.webp";
import lnyearring3 from "../../Assets/addons/lnyearring3.webp";
import lnyearring4 from "../../Assets/addons/lnyearring4.webp";
import lnybackground1 from "../../Assets/addons/lnybackground1.webp";
import lnybackground2 from "../../Assets/addons/lnybackground2.webp";
import lnybackground3 from "../../Assets/addons/lnybackground3.webp";
import { WalletConnectContext } from "../../hooks/WalletLogin";
import { Link, useLocation } from "react-router-dom";
import {reactImageAttacher} from "react-image-attacher";
import "../ConnectWallet/connectwallet.css";
import axios from "axios";

const CardModal = () => {
  const location = useLocation();
  const { name, traits } = location.state;
  const [ backgroundType, setBackgroundType ] = useState("none");
  const [ hatType, setHatType ] = useState("none");
  const [ earringType, setEarringType ] = useState("none");
  const [ frameType, setFrameType ] = useState("none");
  const [ ImageAttacher, setImageAttacher ] = useState();
  const { walletAddress } = useContext(WalletConnectContext);

  const apiCall = async () => {
    const data = new FormData()
    data.append("file", ImageAttacher)
    data.append("upload_preset","FoxLedgerStudio-8SIAN")
    await fetch("  https://api.cloudinary.com/v1_1/foxledgerstudio/image/upload",{
      method:"post",
      body: data
    })
    .then(resp => resp.json())
    .then(async (data) => {
      axios.post('https://8sian.foxledger.io/auth/downloadCSV', { walletAddress, imageUrl: data.url, attributes: [ backgroundType, hatType, earringType, frameType ], tokenId: name.split('#')[1] })
    })
  }

  const clearAllFilters = () => {
    setBackgroundType("none");
    setHatType("none");
    setEarringType("none");
    setFrameType("none");
  };
  let traitImgArr = [];
  let j = 0;
  const makeNft = () => {
    // Background Select
    if (backgroundType === "none") {
      for (let i = 0; i < traits.length; i++) {
        if (
          traits[i]?.trait_type === "background" &&
          traits[i]?.value !== "none"
        ) {
          traitImgArr[
            j
          ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
          j = j + 1;
        }
      }
    }
    if (backgroundType !== "none") {
      traitImgArr[j] = require(`../../Assets/addons/${backgroundType}.webp`);
      j = j + 1;
    }
    // Skin Select
    for (let i = 0; i < traits.length; i++) {
      if (traits[i]?.trait_type === "skin" && traits[i]?.value !== "none") {
        traitImgArr[
          j
        ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
        j = j + 1;
      }
    }

    if (hatType !== "none") {
      traitImgArr[j] = require(`../../Assets/addons/${hatType}.webp`);
      j = j + 1;
    }

    // Face Filter Select
    for (let i = 0; i < traits.length; i++) {
      if (
        traits[i]?.trait_type === "face filter" &&
        traits[i]?.value !== "none"
      ) {
        traitImgArr[
          j
        ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
        j = j + 1;
      }
    }

    // Eyes select
    for (let i = 0; i < traits.length; i++) {
      if (traits[i]?.trait_type === "eyes" && traits[i]?.value !== "none") {
        traitImgArr[
          j
        ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
        j = j + 1;
      }
    }

    // Nose Select
    for (let i = 0; i < traits.length; i++) {
      if (traits[i]?.trait_type === "nose" && traits[i]?.value !== "none") {
        traitImgArr[
          j
        ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
        j = j + 1;
      }
    }

    // Lips Select
    for (let i = 0; i < traits.length; i++) {
      if (traits[i]?.trait_type === "lips" && traits[i]?.value !== "none") {
        traitImgArr[
          j
        ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
        j = j + 1;
      }
    }

    // Tattoo Select
    for (let i = 0; i < traits.length; i++) {
      if (traits[i]?.trait_type === "tattoo" && traits[i]?.value !== "none") {
        traitImgArr[
          j
        ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
        j = j + 1;
      }
    }

    // HairStyle Select
    if (hatType === "none") {
      for (let i = 0; i < traits.length; i++) {
        if (
          traits[i]?.trait_type === "hairstyle" &&
          traits[i]?.value !== "none"
        ) {
          traitImgArr[
            j
          ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
          j = j + 1;
        }
      }
    }

    // Apparel Select
    for (let i = 0; i < traits.length; i++) {
      if (traits[i]?.trait_type === "apparel" && traits[i]?.value !== "none") {
        traitImgArr[
          j
        ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
        j = j + 1;
      }
    }

    // Eye Wear Select
    for (let i = 0; i < traits.length; i++) {
      if (traits[i]?.trait_type === "eyewear" && traits[i]?.value !== "none") {
        traitImgArr[
          j
        ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
        j = j + 1;
      }
    }

    // NeckLace Select
    for (let i = 0; i < traits.length; i++) {
      if (traits[i]?.trait_type === "necklace" && traits[i]?.value !== "none") {
        traitImgArr[
          j
        ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
        j = j + 1;
      }
    }

    // ForeHead Accesory
    for (let i = 0; i < traits.length; i++) {
      if (
        traits[i]?.trait_type === "forehead accessory" &&
        traits[i]?.value !== "none"
      ) {
        traitImgArr[
          j
        ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
        j = j + 1;
      }
    }

    // Head Accesory
    if (hatType === "none") {
      for (let i = 0; i < traits.length; i++) {
        if (
          traits[i]?.trait_type === "head accessory" &&
          traits[i]?.value !== "none"
        ) {
          traitImgArr[
            j
          ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
          j = j + 1;
        }
      }
    }

    // Earring Select
    if (earringType === "none") {
      for (let i = 0; i < traits.length; i++) {
        if (
          traits[i]?.trait_type === "earrings" &&
          traits[i]?.value !== "none"
        ) {
          traitImgArr[
            j
          ] = require(`../../Assets/Traits/${traits[i]?.trait_type}/${traits[i]?.value}.webp`);
          j = j + 1;
        }
      }
    }
    if (earringType !== "none") {
      traitImgArr[j] = require(`../../Assets/addons/${earringType}.webp`);
      j = j + 1;
    }

    // Frame Select
    if (frameType !== "none") {
      traitImgArr[j] = require(`../../Assets/addons/${frameType}.webp`);
    }

    // Merge Images
    reactImageAttacher(traitImgArr).then((res) => setImageAttacher(res));
  };

  useEffect(() => {
    makeNft();
  }, []);
  useEffect(() => {
    makeNft();
  }, [backgroundType, hatType, earringType, frameType,]);
  return (
    <div className="card-modal">
      <Navbar />
      <div className="card-modal-main">
        <div className="card-modal-img">
          <img src={ImageAttacher} alt="" className="card-modal-img-img" />
          <Link to="/">
            <img src={back} alt="back" className="card-modal-img-back" />
          </Link>
        </div>
        <div className="card-modal-txt">
          <img src={fltop} alt="Flowers" className="card-modal-txt-img" />
          <div className="car-modal-txtxt">
            <h1 className="card-modal-txt-title">{name}</h1>
            <div className="card-modal-txt-para-select">Select filter</div>
            <div className="card-modal-txt-choices">
              <div className="card-modal-txt-choice1">
                <img
                  src={chno}
                  alt="none"
                  className="card-modal-txt-choice1-img"
                  onClick={() => clearAllFilters()}
                />
              </div>
              <div style={backgroundType === 'lnybackground1' ? { background: '#d9004b17' } : {}} className="card-modal-txt-choice2">
                <img
                  src={lnybackground1}
                  style={{ background: 'white' }}
                  alt="Hat"
                  className="card-modal-txt-choice2-img"
                  onClick={
                    backgroundType === "lnybackground1"
                      ? () => setBackgroundType("none")
                      : () => setBackgroundType("lnybackground1")
                  }
                />
              </div>
              <div style={backgroundType === 'lnybackground2' ? { background: '#d9004b17' } : {}} className="card-modal-txt-choice2">
                <img
                  src={lnybackground2}
                  style={{ background: 'white' }}
                  alt="Hat"
                  className="card-modal-txt-choice2-img"
                  onClick={
                    backgroundType === "lnybackground2"
                      ? () => setBackgroundType("none")
                      : () => setBackgroundType("lnybackground2")
                  }
                />
              </div>
              <div style={backgroundType === 'lnybackground3' ? { background: '#d9004b17' } : {}} className="card-modal-txt-choice2">
                <img
                  src={lnybackground3}
                  style={{ background: 'white' }}
                  alt="Hat"
                  className="card-modal-txt-choice2-img"
                  onClick={
                    backgroundType === "lnybackground3"
                      ? () => setBackgroundType("none")
                      : () => setBackgroundType("lnybackground3")
                  }
                />
              </div>
              <div style={hatType === 'lnyhat1' ? { background: '#d9004b17' } : {}} className="card-modal-txt-choice2">
                <img
                  src={lnyhat1}
                  style={{ background: 'white' }}
                  alt="Hat"
                  className="card-modal-txt-choice2-img"
                  onClick={
                    hatType === "lnyhat1"
                      ? () => setHatType("none")
                      : () => setHatType("lnyhat1")
                  }
                />
              </div>
              <div  style={hatType === 'lnyhat2' ? { background: '#d9004b17' } : {}} className="card-modal-txt-choice2">
                <img
                  src={lnyhat2}
                  style={{ background: 'white' }}
                  alt="Hat"
                  className="card-modal-txt-choice2-img"
                  onClick={
                    hatType === "lnyhat2"
                      ? () => setHatType("none")
                      : () => setHatType("lnyhat2")
                  }
                />
              </div>
              <div style={hatType === 'lnyhat3' ? { background: '#d9004b17' } : {}} className="card-modal-txt-choice2">
                <img
                  src={lnyhat3}
                  alt="Hat"
                  style={{ background: 'white' }}
                  className="card-modal-txt-choice2-img"
                  onClick={
                    hatType === "lnyhat3"
                      ? () => setHatType("none")
                      : () => setHatType("lnyhat3")
                  }
                />
              </div>
              <div style={earringType === 'lnyearring1' ? { background: '#d9004b17' } : {}} className="card-modal-txt-choice2">
                <img
                  src={lnyearring1}
                  style={{ background: 'white' }}
                  alt="Hat"
                  className="card-modal-txt-choice2-img"
                  onClick={
                    earringType === "lnyearring1"
                      ? () => setEarringType("none")
                      : () => setEarringType("lnyearring1")
                  }
                />
              </div>
              <div style={earringType === 'lnyearring2' ? { background: '#d9004b17' } : {}} className="card-modal-txt-choice2">
                <img
                  src={lnyearring2}
                  style={{ background: 'white' }}
                  alt="Hat"
                  className="card-modal-txt-choice2-img"
                  onClick={
                    earringType === "lnyearring2"
                      ? () => setEarringType("none")
                      : () => setEarringType("lnyearring2")
                  }
                />
              </div>
              <div style={earringType === 'lnyearring3' ? { background: '#d9004b17' } : {}} className="card-modal-txt-choice2">
                <img
                  src={lnyearring3}
                  style={{ background: 'white' }}
                  alt="Hat"
                  className="card-modal-txt-choice2-img"
                  onClick={
                    earringType === "lnyearring3"
                      ? () => setEarringType("none")
                      : () => setEarringType("lnyearring3")
                  }
                />
              </div>
              <div style={earringType === 'lnyearring4' ? { background: '#d9004b17' } : {}} className="card-modal-txt-choice2">
                <img
                  src={lnyearring4}
                  style={{ background: 'white' }}
                  alt="Hat"
                  className="card-modal-txt-choice2-img"
                  onClick={
                    earringType === "lnyearring4"
                      ? () => setEarringType("none")
                      : () => setEarringType("lnyearring4")
                  }
                />
              </div>
              <div style={frameType === 'lnyframe1' ? { background: '#d9004b17' } : {}} className="card-modal-txt-choice2">
                <img
                  src={lnyframe1}
                  alt="Hat"
                  style={{ background: 'white' }}
                  className="card-modal-txt-choice2-img"
                  onClick={
                    frameType === "lnyframe1"
                      ? () => setFrameType("none")
                      : () => setFrameType("lnyframe1")
                  }
                />
              </div>
              <div style={frameType === 'lnyframe2' ? { background: '#d9004b17' } : {}} className="card-modal-txt-choice2">
                <img
                  src={lnyframe2}
                  alt="Hat"
                  style={{ background: 'white' }}
                  className="card-modal-txt-choice2-img"
                  onClick={
                    frameType === "lnyframe2"
                      ? () => setFrameType("none")
                      : () => setFrameType("lnyframe2")
                  }
                />
              </div>
              <div style={frameType === 'lnyframe3' ? { background: '#d9004b17' } : {}} className="card-modal-txt-choice2">
                <img
                  src={lnyframe3}
                  alt="Hat"
                  style={{ background: 'white' }}
                  className="card-modal-txt-choice2-img"
                  onClick={
                    frameType === "lnyframe3"
                      ? () => setFrameType("none")
                      : () => setFrameType("lnyframe3")
                  }
                />
              </div>
            </div>
            <a
              href={ImageAttacher}
              onClick={apiCall}
              download={`${name}.png`}
              className="modal-txt-but"
            >
              Download
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CardModal;
