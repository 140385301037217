import React from "react";
import "./loader.css";
import loaderLogo from '../../Assets/loaderLogo.png'
const Loader = () => {
  return (
    <div className="loader-div">
      <div style={{ marginBottom: '20px' }} className="content">
        <img className="opacityToggle" style={{ width: '200px' }} src={loaderLogo} />
      </div>
    </div>
  );
};

export default Loader;
