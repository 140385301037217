import React, { useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import "./home.css";
import back from "../../Assets/back.png";
import imgArr from "../../Components/ImgArr/ImgArr";
import btmbg from "../../Assets/btmbg.png";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import lnyBanner from "../../Assets/lnyBanner.png";

const Home = () => {
  const [index, setindex] = useState(1);
  return (
    <>
      <div className="home">
        <Navbar />
        <div className="banner">
        <img  
        src={lnyBanner}
        width="100%"
        height="100%" 
        />
        </div>
        <div className="home-top">
          <div className="home-top-txt">
            <h1 className="home-top-txt-heading">
              Celebrate Lunar New Year 2023 with 8SIAN
            </h1>
            <p className="home-top-txt-para">
              Dress your 8SIAN in 13 Lunar New Year Festive Apparel!
            </p>
            <p className="home-top-txt-para">
              Connect your wallet and follow the simple steps on-screen and join
              us for an abundance of prosperity, joy and success!
            </p>
            <Link to="/connect-wallet">
              <div className="home-top-txt-cta">
                  CONNECT NOW
              </div>
            </Link>
          </div>
          <div className="home-top-img">
            <img
              src={index === 1 ? './images/LNY23 8SIAN (1).png' : index === 2 ? './images/LNY23 8SIAN (2).png' : './images/LNY23 8SIAN (3).png'}
              className="home-top-img-img"
              alt="NFT"
            />
            <div className="home-top-img-controls">
              <img
                src={back}
                alt="Previous"
                className="home-top-img-control-back"
                onClick={
                  index === 1
                    ? () => setindex(3)
                    : index === 2
                    ? () => setindex(1)
                    : () => setindex(2)
                }
              />
              <img
                src={back}
                alt="Previous"
                className="home-top-img-control-forward"
                onClick={
                  index === 3
                    ? () => setindex(1)
                    : index === 2
                    ? () => setindex(3)
                    : () => setindex(2)
                }
              />
            </div>
          </div>
        </div>
        <Marquee className="marquee" gradient={true}>
          <div className="marquee-con">
            <span className="marquee-content">
              <span className="marquee-dot" />
              <span className="marquee-txt">
                Stylize your 8SIAN and enhance its beauty!
              </span>
            </span>
            <span className="marquee-content">
              <span className="marquee-dot" />
              <span className="marquee-txt">
              Stylize your 8SIAN and enhance its beauty!
              </span>
            </span>
            <span className="marquee-content">
              <span className="marquee-dot" />
              <span className="marquee-txt">
              Stylize your 8SIAN and enhance its beauty!
              </span>
            </span>
            <span className="marquee-content">
              <span className="marquee-dot" />
              <span className="marquee-txt">
              Stylize your 8SIAN and enhance its beauty!
              </span>
            </span>
            <span className="marquee-content">
              <span className="marquee-dot" />
              <span className="marquee-txt">
              Stylize your 8SIAN and enhance its beauty!
              </span>
            </span>
            <span className="marquee-content">
              <span className="marquee-dot" />
              <span className="marquee-txt">
              Stylize your 8SIAN and enhance its beauty!
              </span>
            </span>
            <span className="marquee-content">
              <span className="marquee-dot" />
              <span className="marquee-txt">
              Stylize your 8SIAN and enhance its beauty!
              </span>
            </span>
            <span style={{ marginRight: '20px' }} className="marquee-content">
              <span className="marquee-dot" />
              <span className="marquee-txt">
              Stylize your 8SIAN and enhance its beauty!
              </span>
            </span>
          </div>
        </Marquee>
        <img src={btmbg} alt="Wire" className="home-btm-bg" />
        <div className="home-btm">
          <h1 className="home-btm-title">Featured Collaborative Traits</h1>
          <div className="home-btm-img-dsiplay">
            {imgArr.map((nft) => {
              return (
                <div>
                  <img
                    src={nft.image}
                    alt="NFT"
                    className="home-btm-img"
                    key={nft.index}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
